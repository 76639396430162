import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GetCountryDataService } from 'src/shared/services/get-country-data.service';
import { GuideWithoutLoginService } from 'src/shared/services/guide-without-login/guide-without-login.service';
import { Contract } from 'src/shared/services/storage/contract';
import { SecureSimpleStorage } from 'src/shared/services/storage/secure-simple-storage';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    countrySelect: any = null;
    countries: any = [];
    showSelect: boolean = true;
    isCollapsed = true;
    constructor(
        private getCountryData: GetCountryDataService,
        private secureStorageService: SecureSimpleStorage,
        private guideWitoutLoginService: GuideWithoutLoginService
    ) {
        this.countries = this.getCountryData.getMultiCountryDataWithoutLogin();
        this.countrySelect = this.countries[0];
    }

    ngOnInit(): void {
        this.guideWitoutLoginService.currentStep$.subscribe((value: any) => {
            this.showSelect = value;
        });
        this.guideWitoutLoginService.currentCountry$.subscribe((value: any) => {
            if (value) {
                this.countrySelect = value;
            }
        });
    }

    selectCountry(item: any) {
        if (item == this.countrySelect) {
            return;
        }
        this.countrySelect = item;
        this.assignCountry(item);
        this.guideWitoutLoginService.changeCountry(item);
    }

    async assignCountry(item: any) {
        try {
            let userWOL = {
                currentStep: 1,
                parcels: [],
                products: [],
                multiCountryData: item,
            };
            await firstValueFrom(
                this.secureStorageService.save(
                    Contract.userWOL,
                    JSON.stringify(userWOL)
                )
            );
        } catch (error) {}
    }

    toggleNavbar(): void {
        this.isCollapsed = !this.isCollapsed; // Alternar el estado
    }
}
